<template>
  <section class="px-4 py-5">
    <div class="container py-5">
      <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
        <!-- Paket 1 -->
        <div class="col">
          <div class="card mb-3 rounded-3 shadow-sm">
            <div class="card-header">
              <h4 class="my-0 fw-normal">Paket 1</h4>
            </div>
            <div class="card-body">
              <h5 class="text-muted">Starting At</h5>
              <h3 class="card-title">Rp. 200.000</h3>
              <p class="card-subtitle fw-bold">Design Logo Mini</p>
              <ul class="list-unstyled mt-3 mb-4">
                <li>1 Logo</li>
                <li>Export PNG,JPG,PDF, etc.</li>
                <li>File Master(AI/CDR/EPS)</li>
                <li>1 Days Delivery + Revision</li>
              </ul>
              <router-link to="/contacts">
                <button class="btn btn-outline-primary w-100 btn-lg">
                  Contact Us
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <!-- Paket 1 -->

        <!-- Paket 2 -->
        <div class="col">
          <div class="card mb-3 rounded-3 shadow-sm">
            <div class="card-header">
              <h4 class="my-0 fw-normal">Paket 2</h4>
            </div>
            <div class="card-body">
              <h5 class="text-muted">Starting At</h5>
              <h3 class="card-title">Rp. 400.000</h3>
              <p class="card-subtitle fw-bold">Design Logo Medium</p>
              <ul class="list-unstyled mt-3 mb-4">
                <li>1 Logo Primary + 1 Logo Secondary</li>
                <li>Export PNG,JPG,PDF, etc.</li>
                <li>File Master(AI/CDR/EPS)</li>
                <li>1 Days Delivery + Revision</li>
              </ul>
              <router-link to="/contacts">
                <button class="btn btn-info w-100 btn-lg">Contact Us</button>
              </router-link>
            </div>
          </div>
        </div>
        <!-- Paket 2 -->

        <!-- Paket 3 -->
        <div class="col">
          <div class="card mb-3 rounded-3 shadow-sm">
            <div class="card-header">
              <h4 class="my-0 fw-normal">Paket 3</h4>
            </div>
            <div class="card-body">
              <h5 class="text-muted">Starting At</h5>
              <h3 class="card-title">Rp. 500.000</h3>
              <p class="card-subtitle fw-bold">Design Logo Pro(Perusahaan)</p>
              <ul class="list-unstyled mt-3 mb-4">
                <li>1 Logo Primary + 1 Logo Secondary</li>
                <li>Export PNG,JPG,PDF, etc.</li>
                <li>File Master(AI/CDR/EPS)</li>
                <li>Name Card</li>
                <li>1 Days Delivery + Revision</li>
              </ul>
              <router-link to="/contacts">
                <button class="btn btn-info w-100 btn-lg">Contact Us</button>
              </router-link>
            </div>
          </div>
        </div>
        <!-- Paket 3 -->

      </div>
    </div>
  </section>
</template>
