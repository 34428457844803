<template>
  <section class="bg-primary text-white mt-5">
    <div class="container">
      <div class="row justify-content-center py-3">
        <div class="col-md-12 text-center">
          <ul class="list-unstyled list-inline">
            <li class="list-inline-item px-2">
              <a
                href="https://web.facebook.com/zoinix"
                class="text-decoration-none"
                rel="noreferrer noopener"
                target="_blank"
                ><i class="bi fs-3 bi-facebook"></i
              ></a>
            </li>
            <li class="list-inline-item px-2">
              <a
                href="https://wa.me/6281287688905"
                class="text-decoration-none"
                rel="noreferrer noopener"
                target="_blank"
              >
                <i class="bi fs-3 bi-whatsapp"></i>
              </a>
            </li>
            <li class="list-inline-item px-2">
              <a
                href="https://www.instagram.com/zoinix.inter/"
                class="text-decoration-none"
                rel="noreferrer noopener"
                target="_blank"
              >
                <i class="bi fs-3 bi-instagram"></i>
              </a>
            </li>
            <li class="list-inline-item px-2">
              <a
                href="mailto:support@zoinix.com"
                class="text-decoration-none"
                rel="noreferrer noopener"
                target="_blank"
              >
                <i class="bi fs-3 bi-envelope"></i>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-12 text-center">
          <ul class="list-unstyled list-inline">
            <li class="list-inline-item">
              <router-link to="/" class="text-white">Home</router-link>
            </li>
            <li class="list-inline-item">
              <router-link to="/about" class="text-white">About</router-link>
            </li>
            <li class="list-inline-item">
              <router-link to="/portofolio" class="text-white"
                >Portofolio</router-link
              >
            </li>
            <li class="list-inline-item">
              <router-link to="/contacts" class="text-white"
                >Contacts</router-link
              >
            </li>
            <li class="list-inline-item">
              <router-link to="/blog" class="text-white">Blog</router-link>
            </li>
          </ul>
        </div>
        <div
          class="
            col-md-12
            d-flex
            flex-column
            align-items-center
            justify-content-center
          "
        >
          <p>Our Technology is Innovation for Your Bussiness</p>
          <p>&copy; 2021 Zoinix Inter Technology</p>
        </div>
      </div>
    </div>
  </section>
</template>
