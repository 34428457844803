<template>
  <section class="about">
    <Hero />
    <About />
    <Team />
  </section>
</template>

<script>
import { useHead } from "@vueuse/head";
import { defineComponent, computed, reactive } from "vue";
import Hero from "@/components/views/about/hero.vue";
import About from "@/components/views/about/about.vue";
import Team from "@/components/views/about/team.vue";

export default defineComponent({
  setup() {
    const siteData = reactive({
      title: "About | Zoinix Inter Technology",
      url: "https://zoinix.com/about",
    });

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "og:title",
          content: computed(() => siteData.title),
        },
        {
          name: "tw:title",
          content: computed(() => siteData.title),
        },
        {
          name: "og:url",
          content: computed(() => siteData.url),
        },
        {
          name: "twitter:url",
          content: computed(() => siteData.url),
        },
      ],
    });
  },
  components: { Hero, About, Team },
});
</script>
