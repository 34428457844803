import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Portofolio from "../views/Portofolio.vue";
import Contacts from "../views/Contacts.vue";
import Blog from "../views/Blog.vue";
import Article from "../views/Article.vue";
import Web from "../views/services/Web.vue";
import Design from "../views/services/Design.vue";
import VpsId from "../views/services/VpsId.vue";
import VpsSg from "../views/services/VpsSg.vue";
import RdpId from "../views/services/RdpId.vue";
import NotFound from "../views/404.vue";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    component: NotFound,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/portofolio",
    name: "Portofolio",
    component: Portofolio,
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/article/:slug",
    name: "article-slug",
    component: Article,
  },
  {
    path: "/services/web-development",
    name: "web-development",
    component: Web,
  },
  {
    path: "/services/design-graphic",
    name: "design-graphic",
    component: Design,
  },
  {
    path: "/services/vps-indonesia",
    name: "vps-indonesia",
    component: VpsId,
  },
  {
    path: "/services/vps-singapura",
    name: "vps-singapura",
    component: VpsSg,
  },
  {
    path: "/services/rdp-indonesia",
    name: "rdp-indonesia",
    component: RdpId,
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

trackRouter(router);

export default router;
