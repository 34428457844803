<template>
  <section class="my-5">
    <h3 class="text-center">Portofolio Kami</h3>
    <div class="container py-5">
      <div v-if="items && items.length" class="row justify-content-center mb-3">
        <div
          v-for="(item, index) in items.slice(0, 3)"
          :key="item.id"
          class="col-md-4 mb-3"
        >
          <div class="card" @click="() => showImg(index)">
            <img
              :src="
                'https://cdn.statically.io/img/inv.zoinix.com/f=auto,q=50/' +
                item.image.replace('https://inv.zoinix.com/', '')
              "
              class="card-img-top"
              :alt="item.title"
            />
            <div class="card-body text-center">
              <h5 class="card-title">{{ item.title }}</h5>
              <span
                v-for="tag in item.porto_tags"
                :key="tag.id"
                class="badge bg-success"
                role="badge"
                >{{ tag.name }}</span
              >
            </div>
          </div>
        </div>
        <vue-easy-lightbox
          :visible="visible"
          :imgs="
            'https://cdn.statically.io/img/inv.zoinix.com/f=auto/' +
            items[index].image.replace('https://inv.zoinix.com/', '')
          "
          :index="index"
          @hide="handleHide"
        ></vue-easy-lightbox>
      </div>
      <div class="row justify-content-center my-3">
        <div class="col-md-4 text-center">
          <router-link to="/portofolio" class="btn btn-primary" type="button"
            >Lihat Lainnya</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components: {
    VueEasyLightbox,
  },
  data() {
    return {
      items: [],
      visible: false,
      index: 0,
    };
  },
  async created() {
    try {
      const response = await axios.get(`/porto/portos`);
      this.items = response.data;
    } catch (e) {
      this.errors.push(e);
    }
  },
  methods: {
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
.card {
  cursor: pointer;
}
</style>
