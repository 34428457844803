<template>
  <section class="design-graphic">
    <Hero />
    <Pricing />
    <App />
  </section>
</template>

<script>
import { useHead } from "@vueuse/head";
import { defineComponent, computed, reactive } from "vue";
import Hero from "@/components/views/services/design/hero.vue";
import Pricing from "@/components/views/services/design/pricing.vue";
import App from "@/components/views/services/design/app.vue";

export default defineComponent({
  setup() {
    const siteData = reactive({
      title: "Design Graphic | Zoinix Inter Technology",
      url: "https://zoinix.com/services/design-graphic",
    });

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "og:title",
          content: computed(() => siteData.title),
        },
        {
          name: "tw:title",
          content: computed(() => siteData.title),
        },
        {
          name: "og:url",
          content: computed(() => siteData.url),
        },
        {
          name: "twitter:url",
          content: computed(() => siteData.url),
        },
      ],
    });
  },
  components: { Hero, Pricing, App },
});
</script>
