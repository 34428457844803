<template>
  <section class="px-4 py-5 text-center text-white">
    <div class="container py-5">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <h1 class="display-5 fw-bold">VPS Indonesia</h1>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
section {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1017%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='rgba(14%2c 42%2c 71%2c 1)'%3e%3c/rect%3e%3cpath d='M848 668.28C960.56 667.62 1049.2 573.83 1286.61 566.65 1524.02 559.47 1597.72 313.29 1725.22 303.45' stroke='rgba(51%2c 121%2c 194%2c 0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M153.95 582.35C299.82 555.42 361.72 193.73 597.94 188.74 834.17 183.75 819.94 258.74 1041.94 258.74 1263.93 258.74 1373.56 188.95 1485.93 188.74' stroke='rgba(51%2c 121%2c 194%2c 0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M303.73 565.81C491.34 534.54 587.85 76.63 885.23 75.23 1182.61 73.83 1309.45 311.33 1466.73 316.03' stroke='rgba(51%2c 121%2c 194%2c 0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M297.85 660.9C510.99 628.3 621.21 121.13 968.05 118.06 1314.88 114.99 1464.45 301.18 1638.24 302.86' stroke='rgba(51%2c 121%2c 194%2c 0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M148.06 582.5C345.67 573.5 482.42 243.93 859.72 236.62 1237.01 229.31 1389.19 80.75 1571.37 79.82' stroke='rgba(51%2c 121%2c 194%2c 0.58)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1017'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
  background-attachment: fixed;
  background-size: cover;
}
</style>
