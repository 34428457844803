<template>
  <section class="px-4 py-5 text-center text-white">
    <div class="container py-5">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <h1 class="display-5 fw-bold">Design Grapich</h1>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
section {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1008%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='rgba(14%2c 42%2c 71%2c 1)'%3e%3c/rect%3e%3cpath d='M514.05 652.52C618.61 646.04 581.45 460.93 881.39 450.9 1181.33 440.87 1420.05 180.93 1616.06 176.5' stroke='rgba(51%2c 121%2c 194%2c 0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M363.67 627.04C526.54 573.23 554.43 111.94 800.94 98.3 1047.46 84.66 1019.58 168.3 1238.22 168.3 1456.85 168.3 1564.78 98.52 1675.49 98.3' stroke='rgba(51%2c 121%2c 194%2c 0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M167.19 590.1C334.52 533.28 360.71 56.62 613.39 41.86 866.08 27.1 836.49 111.86 1059.6 111.86 1282.7 111.86 1392.89 42.07 1505.8 41.86' stroke='rgba(51%2c 121%2c 194%2c 0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M197.76 567.09C346.14 535.95 401.8 156.68 638.51 150.53 875.22 144.38 858.89 220.53 1079.26 220.53 1299.64 220.53 1408.44 150.75 1520.01 150.53' stroke='rgba(51%2c 121%2c 194%2c 0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M310.82 629.74C439.39 624.64 541.73 414.81 778.26 414.38 1014.79 413.95 1011.98 484.38 1245.71 484.38 1479.43 484.38 1594.99 414.57 1713.15 414.38' stroke='rgba(51%2c 121%2c 194%2c 0.58)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1008'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
  background-attachment: fixed;
  background-size: cover;
}
</style>
