import axios from "axios";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createHead } from "@vueuse/head";
import VueGtag from "vue-gtag-next";

axios.defaults.baseURL = "https://inv.zoinix.com/api";
const head = createHead();

createApp(App)
  .use(router)
  .use(head)
  .use(VueGtag, { property: { id: "UA-207439091-2" } })
  .mount("#app");
