<template>
  <section class="404">
    <section class="px-4 py-5 text-center">
      <div class="container py-5">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <h1 class="display-5 fw-bold">404</h1>
            <h2 class="display-6 fw-normal">Page Not Found</h2>
            <p class="lead">
              Halaman yang anda cari tidak ditemukan atau terjadi error
            </p>
            <router-link to="/">
              <button class="btn btn-lg btn-primary">Back To Home</button>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>
