<template>
  <section class="px-4 py-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12 text-center mb-3">
          <h2 class="display-6">Team Kami</h2>
        </div>
        <div class="col-md-12 text-center mb-3">
          <div class="row justify-content-center">
            <div class="col-md-3 mb-3">
              <div class="card">
                <img
                  class="card-img-top"
                  src="https://cdn.pixabay.com/photo/2015/03/04/22/35/head-659652_960_720.png"
                />
                <div class="card-body">
                  <h5 class="card-title">Dandy Bagus Prasetyo</h5>
                  <p class="card-subtitle">CEO & Founder</p>
                </div>
              </div>
            </div>
            <div class="col-md-3 mb-3">
              <div class="card">
                <img
                  class="card-img-top"
                  src="https://cdn.pixabay.com/photo/2015/03/04/22/35/head-659652_960_720.png"
                />
                <div class="card-body">
                  <h5 class="card-title">Ahmad Azwar Annas</h5>
                  <p class="card-subtitle">Project Manager & Co-Founder</p>
                </div>
              </div>
            </div>
            <div class="col-md-3 mb-3">
              <div class="card">
                <img
                  class="card-img-top"
                  src="https://cdn.pixabay.com/photo/2015/03/04/22/35/head-659652_960_720.png"
                />
                <div class="card-body">
                  <h5 class="card-title">Padilah Ahmad</h5>
                  <p class="card-subtitle">Lead Designer</p>
                </div>
              </div>
            </div>
            <div class="col-md-3 mb-3">
              <div class="card">
                <img
                  class="card-img-top"
                  src="https://cdn.pixabay.com/photo/2015/03/04/22/35/head-659652_960_720.png"
                />
                <div class="card-body">
                  <h5 class="card-title">Veronica Rangga</h5>
                  <p class="card-subtitle">Lead Sales</p>
                </div>
              </div>
            </div>
            <div class="col-md-3 mb-3">
              <div class="card">
                <img
                  class="card-img-top"
                  src="https://cdn.pixabay.com/photo/2015/03/04/22/35/head-659652_960_720.png"
                />
                <div class="card-body">
                  <h5 class="card-title">Juan Daniel</h5>
                  <p class="card-subtitle">Software Engineer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
