<template>
  <section class="px-4 py-5 text-white text-center">
    <div class="container py-5">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <h1 class="display-5 fw-bold">Hubungi Kami</h1>
          <p class="lead">Kami siap membantu anda kapanpun dan dimanapun</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
section {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1003%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='%230e2a47'%3e%3c/rect%3e%3cpath d='M1615.89 200.96C1495.37 201.86 1390.14 320.63 1148.37 318.56 906.6 316.49 827.62-25.34 680.85-46.03' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M1480.67 147.14C1362.85 158.09 1285.71 405.41 1083.62 404.74 881.52 404.07 824.86 30.19 686.56-4.03' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M1617.88 110.82C1465.08 112.3 1325.29 273.46 1028.61 273.22 731.93 272.98 604.59-19.67 439.34-28.25' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M1607.98 408.19C1504.49 407.94 1403.99 338.19 1200 338.19 996 338.19 1022.92 421.58 792.01 408.19 561.11 394.8 537.65-44.88 384.03-97.89' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M1587.55 196.46C1396.35 199.8 1139.42 443.67 863.39 442.86 587.36 442.05 637.04 44.45 501.31-1.55' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1003'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
  background-attachment: fixed;
  background-size: cover;
}
</style>
