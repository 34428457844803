<template>
  <section class="px-4 py-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 text-center">
          <h2 class="display-6">Tentang Kami</h2>
          <p class="lead">
            Zoinix memberikan sumber alternatif terbaik untuk solusi bisnis dan
            perusahaan anda dalam mengembangkan sebuah aplikasi, website atau
            apapun dalam bidang teknologi.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
