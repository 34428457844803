<template>
  <section class="home">
    <Hero />
    <About />
    <Help />
    <Portofolio />
  </section>
</template>

<script>
import Hero from "@/components/views/home/hero.vue";
import About from "@/components/views/home/about.vue";
import Help from "@/components/views/home/help.vue";
import Portofolio from "@/components/views/home/portofolio.vue";

export default {
  name: "Home",
  components: { Hero, About, Help, Portofolio },
};
</script>
