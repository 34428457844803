<template>
  <section class="text-white px-4 py-5 text-center">
    <div class="py-5">
      <h1 class="display-5 fw-bold">Zoinix Inter Technology</h1>
      <div class="col-lg-6 mx-auto">
        <p class="fs-5 mb-4">Our Technology is Innovation for Your Business</p>
        <router-link to="/contacts" class="btn btn-success mx-2" type="button"
          >Contact Us</router-link
        >
        <router-link to="/about" class="btn btn-info mx-2" type="button"
          >About Us</router-link
        >
      </div>
    </div>
  </section>
</template>

<style scoped>
section {
  width: 100%;
  height: auto;
  background-image: linear-gradient(90deg, #0f2027 0, #203a43 50%, #2c5364),
    url("https://ik.imagekit.io/bic2e5opzz4/hero-background_ydraOT8Epp.webp");
  background-blend-mode: multiply;
  background-position: center center;
  object-fit: cover;
}
</style>
