<template>
  <section class="px-4 py-5 text-white">
    <div class="container py-5">
      <div class="row justify-content-center">
        <div class="col-md-6 text-center">
          <h1 class="display-5 fw-bold">Portofolio Zoinix</h1>
          <p class="lead">Daftar Proyek yang Pernah Kami Kerjakan</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
section {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1057%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='%230e2a47'%3e%3c/rect%3e%3cpath d='M502.03 635.27C654.76 579.42 673.65 136.55 901.69 122.05 1129.72 107.55 1101.52 192.05 1301.34 192.05 1501.17 192.05 1599.57 122.31 1701 122.05' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M789.04 587.82C937.59 527.12 963.48 74.18 1166.6 68.72 1369.72 63.26 1435.39 279.44 1544.16 287.12' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M856.96 605.46C1017.1 551.13 1051.14 88.97 1284.05 80.94 1516.97 72.91 1597.9 229.93 1711.15 232.14' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M129.06 587.97C246.86 585.98 351.52 437.98 575.78 437.9 800.04 437.82 799.14 507.9 1022.5 507.9 1245.86 507.9 1356.18 438.11 1469.22 437.9' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M306.87 564.15C498.96 539.07 605.1 101.05 925.87 98.05 1246.64 95.05 1386.81 226.17 1544.87 226.85' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1057'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
  background-attachment: fixed;
  background-size: cover;
}
</style>
