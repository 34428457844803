<template>
  <section class="px-4 py-5">
    <div class="container py-5">
      <div class="row row-cols-1 row-cols-md-4 mb-3 text-center">
        <!-- Paket 1 -->
        <div class="col">
          <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header">
              <h4 class="my-0 fw-normal">Paket 1</h4>
            </div>
            <div class="card-body">
              <h5 class="text-muted">Starting At</h5>
              <h3 class="card-title">Rp. 500.000</h3>
              <p class="card-subtitle fw-bold">Simple Web Profile</p>
              <ul class="list-unstyled mt-3 mb-4">
                <li>1 Landing Page</li>
                <li>1 Menu</li>
                <li>Social Media Connection</li>
                <li>Unmanaged Website</li>
                <li>Professional Design</li>
                <li>7 Days Delivery + Revision</li>
              </ul>
              <router-link to="/contacts">
                <button class="btn btn-outline-primary w-100 btn-lg">
                  Contact Us
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <!-- Paket 1 -->

        <!-- Paket 2 -->
        <div class="col">
          <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header">
              <h4 class="my-0 fw-normal">Paket 2</h4>
            </div>
            <div class="card-body">
              <h5 class="text-muted">Starting At</h5>
              <h3 class="card-title">Rp. 1.200.000</h3>
              <p class="card-subtitle fw-bold">Standard Web Profile</p>
              <ul class="list-unstyled mt-3 mb-4">
                <li>Unlimited Landing Page</li>
                <li>Unlimited Menu</li>
                <li>Social Media Connection</li>
                <li>Content Management System</li>
                <li>Managed Website</li>
                <li>Professional Design</li>
                <li>14 Days Delivery + Revision</li>
              </ul>
              <router-link to="/contacts">
                <button class="btn btn-info w-100 btn-lg">Contact Us</button>
              </router-link>
            </div>
          </div>
        </div>
        <!-- Paket 2 -->

        <!-- Paket 3 -->
        <div class="col">
          <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header">
              <h4 class="my-0 fw-normal">Paket 3</h4>
            </div>
            <div class="card-body">
              <h5 class="text-muted">Starting At</h5>
              <h3 class="card-title">Rp. 2.000.000</h3>
              <p class="card-subtitle fw-bold">Standard E-Commerce</p>
              <ul class="list-unstyled mt-3 mb-4">
                <li>Standard Menu</li>
                <li>Integrated Ongkir System</li>
                <li>Integrated Payment System</li>
                <li>Unlimited Product Page</li>
                <li>Social Media Connection</li>
                <li>Managed Website</li>
                <li>Professional Design</li>
                <li>14 Days Delivery + Revision</li>
              </ul>
              <router-link to="/contacts">
                <button class="btn btn-info w-100 btn-lg">Contact Us</button>
              </router-link>
            </div>
          </div>
        </div>
        <!-- Paket 3 -->

        <!-- Paket 4 -->
        <div class="col">
          <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header">
              <h4 class="my-0 fw-normal">Paket 4</h4>
            </div>
            <div class="card-body">
              <h5 class="text-muted">Starting At</h5>
              <h3 class="card-title">Rp. 2.500.000</h3>
              <p class="card-subtitle fw-bold">Mixed Website</p>
              <ul class="list-unstyled mt-3 mb-4">
                <li>Unlimited Landing Pages</li>
                <li>Web Profile Menu</li>
                <li>Content Management System</li>
                <li>E-Commerce Menu</li>
                <li>Integrated Ongkir System</li>
                <li>Integrated Payment System</li>
                <li>Unlimited Product Pages</li>
                <li>Social Media Connection</li>
                <li>Managed Website</li>
                <li>Professional Design</li>
                <li>14 Days Delivery + Revision</li>
              </ul>
              <router-link to="/contacts">
                <button class="btn btn-info w-100 btn-lg">Contact Us</button>
              </router-link>
            </div>
          </div>
        </div>
        <!-- Paket 4 -->
      </div>
    </div>
  </section>
</template>
