<template>
  <section class="px-4 py-5">
    <div class="container py-5">
      <div class="row row-cols-1 row-cols-md-4 mb-3 text-center">
        <!-- Paket 1 -->
        <div class="col">
          <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header">
              <h4 class="my-0 fw-normal">VPS ID 1</h4>
            </div>
            <div class="card-body">
              <h3 class="card-title">Rp. 70.000 / bulan</h3>
              <ul class="list-unstyled mt-3 mb-4">
                <li>1 Core CPU</li>
                <li>1 GB RAM</li>
                <li>20 GB Storage</li>
                <li>Unlimited Bandwidth</li>
              </ul>
              <router-link to="/contacts">
                <button class="btn btn-outline-primary w-100 btn-lg">
                  Contact Us
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <!-- Paket 1 -->

        <!-- Paket 2 -->
        <div class="col">
          <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header">
              <h4 class="my-0 fw-normal">VPS ID 2</h4>
            </div>
            <div class="card-body">
              <h3 class="card-title">Rp. 80.000 / bulan</h3>
              <ul class="list-unstyled mt-3 mb-4">
                <li>1 Core CPU</li>
                <li>2 GB RAM</li>
                <li>20 GB Storage</li>
                <li>Unlimited Bandwidth</li>
              </ul>
              <router-link to="/contacts">
                <button class="btn btn-info w-100 btn-lg">Contact Us</button>
              </router-link>
            </div>
          </div>
        </div>
        <!-- Paket 2 -->

        <!-- Paket 3 -->
        <div class="col">
          <div class="card mb-4 rounded-3 shadow-sm border-success">
            <div class="card-header">
              <h4 class="my-0 fw-normal">VPS ID 3</h4>
            </div>
            <div class="card-body">
              <h3 class="card-title">Rp. 100.000 / bulan</h3>
              <ul class="list-unstyled mt-3 mb-4">
                <li>2 Core CPU</li>
                <li>2 GB RAM</li>
                <li>20 GB Storage</li>
                <li>Unlimited Bandwidth</li>
              </ul>
              <router-link to="/contacts">
                <button class="btn btn-success w-100 btn-lg">Contact Us</button>
              </router-link>
            </div>
          </div>
        </div>
        <!-- Paket 3 -->

        <!-- Paket 4 -->
        <div class="col">
          <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header">
              <h4 class="my-0 fw-normal">VPS ID 4</h4>
            </div>
            <div class="card-body">
              <h3 class="card-title">Rp. 280.000 / bulan</h3>
              <ul class="list-unstyled mt-3 mb-4">
                <li>2 Core CPU</li>
                <li>4 GB RAM</li>
                <li>20 GB Storage</li>
                <li>Unlimited Bandwidth</li>
              </ul>
              <router-link to="/contacts">
                <button class="btn btn-info w-100 btn-lg">Contact Us</button>
              </router-link>
            </div>
          </div>
        </div>
        <!-- Paket 4 -->
      </div>
    </div>
  </section>
</template>
