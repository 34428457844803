<template>
  <section class="vps-indonesia">
    <Hero />
    <Pricing />
    <Benefit />
  </section>
</template>

<script>
import { useHead } from "@vueuse/head";
import { defineComponent, computed, reactive } from "vue";
import Hero from "@/components/views/services/rdpid/hero.vue";
import Pricing from "@/components/views/services/rdpid/pricing.vue";
import Benefit from "@/components/views/services/rdpid/benefit.vue";

export default defineComponent({
  setup() {
    const siteData = reactive({
      title: "RDP Indonesia | Zoinix Inter Technology",
      url: "https://zoinix.com/services/rdp-indonesia",
    });

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "og:title",
          content: computed(() => siteData.title),
        },
        {
          name: "tw:title",
          content: computed(() => siteData.title),
        },
        {
          name: "og:url",
          content: computed(() => siteData.url),
        },
        {
          name: "twitter:url",
          content: computed(() => siteData.url),
        },
      ],
    });
  },
  components: { Hero, Pricing, Benefit },
});
</script>
