<template>
  <section class="my-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 d-flex align-items-center justify-content-start">
          <p class="lead">
            Zoinix memberikan sumber alternatif terbaik untuk solusi bisnis dan
            perusahaan anda dalam mengembangkan sebuah aplikasi, website atau
            apapun dalam bidang teknologi.
          </p>
        </div>
        <div class="col-md-6 text-center">
          <img
            src="https://ik.imagekit.io/bic2e5opzz4/about_SF32r9wtpe.webp"
            loading="lazy"
            class="img-fluid rounded"
            alt="2 komputer mac dengan drawing tab didepannya"
          />
        </div>
      </div>
    </div>
  </section>
</template>
