<template>
  <header>
    <Navbar />
  </header>
  <main>
    <router-view />
  </main>
  <footer>
    <Footer />
  </footer>
</template>

<script>
import { useHead } from "@vueuse/head";
import { defineComponent, computed, reactive } from "vue";

import Navbar from "@/components/layouts/navbar.vue";
import Footer from "@/components/layouts/footer.vue";

export default defineComponent({
  setup() {
    const siteData = reactive({
      title: "Home | Zoinix Inter Technology",
      description:
        "Jasa pembuatan website, ui/ux, aplikasi profesional perusahaan, bisnis & ukm, dll. desain elegan, murah dapat dipercaya 24/7",
      type: "website",
      image: "https://zoinix.com/logo.png",
      url: "https://zoinix.com",
      canonical: "https://zoinix.com",
    });

    useHead({
      title: computed(() => siteData.title),
      htmlAttrs: [{ lang: "en", amp: true }],
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description),
        },
        {
          name: "canonical",
          content: computed(() => siteData.canonical),
        },
        {
          name: "robots",
          content: `index, follow`,
        },
        {
          property: "og:type",
          content: computed(() => siteData.type),
        },
        {
          property: "og:title",
          content: computed(() => siteData.title),
        },
        {
          property: "og:description",
          content: computed(() => siteData.description),
        },
        {
          property: "og:image",
          content: computed(() => siteData.image),
        },
        {
          property: "og:url",
          content: computed(() => siteData.url),
        },
        {
          name: "twitter:title",
          content: computed(() => siteData.title),
        },
        {
          name: "twitter:description",
          content: computed(() => siteData.description),
        },
        {
          name: "twitter:image",
          content: computed(() => siteData.image),
        },
      ],
    });
  },
  components: { Navbar, Footer },
});
</script>

<style>
#app {
  padding-top: 85px;
}
</style>
