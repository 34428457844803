<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top">
    <div class="container">
      <router-link to="/" class="navbar-brand">
        <img
          class="img-fluid"
          width="40"
          src="/logo.png"
          alt="Zoinix Inter Technology Logo"
        />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbar"
        aria-controls="navbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div id="navbar" class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link to="/" class="nav-link">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about" class="nav-link">About</router-link>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              >Services</a
            >
            <div class="dropdown-menu">
              <router-link class="dropdown-item" to="/services/web-development"
                >Web Development</router-link
              >
              <router-link class="dropdown-item" to="/services/design-graphic"
                >Design Graphic</router-link
              >
              <router-link class="dropdown-item" to="/services/vps-indonesia"
                >VPS Indonesia</router-link
              >
              <router-link class="dropdown-item" to="/services/vps-singapura"
                >VPS Singapura</router-link
              >
              <router-link class="dropdown-item" to="/services/rdp-indonesia"
                >RDP Indonesia</router-link
              >
            </div>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/portofolio"
              >Portofolio</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/contacts">Contacts</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/blog">Blog</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
