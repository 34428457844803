<template>
  <section class="px-4 py-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-4">
          <h5 class="fw-bold">Daftar Kontak Kami</h5>
          <ul class="list-unstyled px-0">
            <li class="list-unstyled-item mb-2">
              <a
                href="https://facebook.com/zoinix"
                class="text-decoration-none"
                rel="noreferrer noopener"
                target="_blank"
              >
                <i class="bi fs-6 bi-facebook"></i> Facebook
              </a>
            </li>
            <li class="list-unstyled-item mb-2">
              <a
                href="https://wa.me/62812876889059"
                class="text-decoration-none"
                rel="noreferrer noopener"
                target="_blank"
              >
                <i class="bi fs-6 bi-whatsapp"></i> Whatsapp
              </a>
            </li>
            <li class="list-unstyled-item mb-2">
              <a
                href="https://www.instagram.com/zoinix.inter/"
                class="text-decoration-none"
                rel="noreferrer noopener"
                target="_blank"
              >
                <i class="bi fs-6 bi-instagram"></i> Instagram
              </a>
            </li>
            <li class="list-unstyled-item mb-2">
              <a
                href="mailto:support@zoinix.com"
                class="text-decoration-none"
                rel="noreferrer noopener"
                target="_blank"
                ><i class="bi fs-6 bi-envelope"></i> Email</a
              >
            </li>
          </ul>
        </div>
        <div class="col-md-4">
          <h5 class="fw-bold">Kirimi Kami Pesan</h5>
          <p>Isi form berikut untuk mengirimi kami pesan</p>
          <div class="card">
            <div class="card-body">
              <form>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Judul"
                    v-model="subject"
                    required
                  />
                </div>
                <div class="mb-3">
                  <textarea
                    type="text"
                    class="form-control"
                    rows="10"
                    placeholder="Isi Pesan"
                    v-model="body"
                    required
                  />
                </div>
                <div class="mb-3">
                  <a
                    class="btn btn-primary"
                    type="button"
                    :href="`mailto:support@zoinix.com?subject=${subject}&body=${body}`"
                    >Kirim</a
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      subject: "",
      body: "",
    };
  },
};
</script>
