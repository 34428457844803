<template>
  <section class="px-4 py-5 text-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-4">
          <p class="lead fw-bold">Apa Saja Yang Anda Dapatkan ?</p>
          <div class="card border-info">
            <div class="card-body">
              <ul class="list-unstyled mt-3 mb-4">
                <li>Full Access Root</li>
                <li>Lokasi Server Indonesia</li>
                <li>Fast SSD Cloud Storage</li>
                <li>Uptime 99%</li>
                <li>Support 24 Jam</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
