<template>
  <section class="article">
    <div v-if="loading" class="loading">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <div v-if="error" class="error">{{ error }}</div>

    <div v-if="article" class="content">
      <Head>
        <title>{{ article.title + " | Zoinix Inter Technology" }}</title>
        <meta
          property="og:title"
          :content="`${article.title} | Zoinix Inter
        Technology`"
        />
        <meta property="description" :content="`${article.description}`" />
        <meta property="og:description" :content="`${article.description}`" />
        <meta
          name="twitter:title"
          :content="`${article.title} | Zoinix Inter
        Technology`"
        />
      </Head>
      <div class="container py-5">
        <div class="row justify-content-center text-center">
          <div class="col-md-12 my-2">
            <h1 class="display-5 fw-medium">{{ article.title }}</h1>
            <p class="lead">{{ article.created_at }}</p>
          </div>
          <div class="col-md-12 my-2 justify-content-center">
            <img
              :src="article.image"
              class="img-fluid"
              width="300"
              height="300"
              loading="lazy"
            />
          </div>
          <div class="col-md-12 my-2">
            <p>Oleh: {{ article.user.name }} - {{ article.created_at }}</p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-8" v-html="article.content"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Head } from "@vueuse/head";
import axios from "axios";
export default {
  components: { Head },
  data() {
    return {
      loading: false,
      article: [],
      error: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.error = this.article = null;
      this.loading = true;
      const fetchedSlug = this.$route.params.slug;
      try {
        const response = await axios.get(`/blog/post/${fetchedSlug}`);
        this.article = response.data;
        this.loading = false;
      } catch (e) {
        this.error = e.toString();
      }
    },
  },
};
</script>
