<template>
  <div v-if="posts && posts.length" class="row justify-content-center">
    <div v-for="post in posts" :key="post.id" class="col-md-3">
      <div class="card mb-3">
        <img
          :src="post.image"
          class="card-img-top"
          :alt="post.description"
          loading="lazy"
        />
        <div class="card-body text-center">
          <h5 class="card-title fw-bold">{{ post.title }}</h5>
          <!-- <p class="card-subtitle text-muted">{{ post.created_at }}</p> -->
          <p class="card-text">{{ post.description }}</p>
          <router-link
            :to="{ name: 'article-slug', params: { slug: post.slug } }"
            class="btn btn-success"
            type="button"
            >Read More</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      posts: [],
    };
  },
  async created() {
    try {
      const response = await axios.get("/blog/posts");
      this.posts = response.data;
    } catch (e) {
      this.errors.push(e);
    }
  },
};
</script>

<style scoped>
.card-img-top {
  height: 20vh;
  object-fit: cover;
}
</style>
