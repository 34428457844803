<template>
  <section class="px-4 py-5 text-center">
    <div class="container py-5">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <h3 class="fw-bold">Paket Yang Anda Butuhkan Tidak Ada ?</h3>
          <p class="lead">
            Segera Hubungi Kami, Akan Kami Bantu Menyusun Paket Yang Sesuai
            Untuk Anda
          </p>
          <router-link to="/contacts">
            <button class="btn btn-lg btn-info">Hubungi Kami</button>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>
