<template>
  <section class="px-4 py-5 text-white text-center">
    <div class="container py-5">
      <div class="row justify-content-center">
        <div class="col-md-12 text-center">
          <h1 class="display-5 fw-bold">Zoinix Inter Technology</h1>
          <p class="lead">Our Technology is Innovation for Your Business</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
section {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1028%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='%230e2a47'%3e%3c/rect%3e%3cpath d='M231.1 600.44C432.28 566.07 534.75 71.76 851.99 70.35 1169.23 68.94 1303.83 333.46 1472.88 339.15' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M547.78 574.25C733.15 546.41 833.71 106.09 1132.38 104.77 1431.04 103.45 1560.51 324.98 1716.97 328.77' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M743.79 659.05C839.2 657.31 898.88 547.5 1104.61 534.51 1310.34 521.52 1353.1 275.1 1465.43 260.11' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M240.02 607.66C380.32 581.73 440.02 233.66 666.96 228.97 893.91 224.28 880.43 298.97 1093.91 298.97 1307.38 298.97 1412.69 229.2 1520.85 228.97' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M524.73 660.46C649.62 608.22 597.19 221.79 840.17 220.32 1083.16 218.85 1304.18 435.66 1471.06 438.72' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1028'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
  background-attachment: fixed;
  background-size: cover;
}
</style>
